module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-6TCQZEC0KQ","AW-993618215"],"gtagConfig":{"optimize_id":"GTM-WXK9T9G'","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"delayOnRouteUpdate":0,"origin":"https://www.googletagmanager.com"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"be8c412c8cca3939bc8eb798b4ce484f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Energie Piscines","short_name":"Energie Piscines","description":"Entretien de piscines","start_url":"/","background_color":"#005c70","theme_color":"#005c70","display":"standalone","icon":"./src/assets/images/favicon.ico","icons":[{"src":"./src/assets/images/favicons/48.png","sizes":"48x48","type":"image/png"},{"src":"./src/assets/images/favicons/72.png","sizes":"72x72","type":"image/png"},{"src":"./src/assets/images/favicons/144.png","sizes":"144x144","type":"image/png"},{"src":"./src/assets/images/favicons/192.png","sizes":"192x192","type":"image/png"},{"src":"./src/assets/images/favicons/512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"be8c412c8cca3939bc8eb798b4ce484f"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
